import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Image } from 'semantic-ui-react'
const MyCarousel = ({ Images, style }) => {
    const elements = Images.map((item, i) => {
        if (item.Url) {
            return (<a href={item.Url} target={item.Target} key={i}>
                <Image fluid src={item.Image?.childImageSharp?.sizes?.src} />
            </a>)
        }
        else {
            return (<div key={i}>
                <Image fluid src={item.Image?.childImageSharp?.sizes?.src} />
            </div>)
        }
    })

    return (
        <div style={style}>
            <Carousel showStatus={false} infiniteLoop autoPlay showThumbs={false}>
                {elements}
            </Carousel>
        </div>
    )

}
export default MyCarousel;